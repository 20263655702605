/* Footer.css */

/* Container for the entire footer */
.footer {
  background-color: #333;
  color: #fff;
  padding: 10px;
  font-size: 12px;
  bottom: 0;
}

.footer a {
  display: block;
}

.footer * {
  margin-top: 0.7em;
  margin-bottom: 0.7em;
}

/* Change link color on hover */
.footer a.website-link:hover,
.footer a.resource-link:hover,  
.acknowledgements-link:hover,
.welcome-page-link:hover,
.disclaimer-link:hover,
.privacy-link:hover {
  color: #ff5733; 
}

/* Style for the website links */
.footer a.resource-link {
  color: #fff;
  text-decoration: none;
}

.footer a.website-link {
  color: #fff;
  text-decoration: none;
}

.acknowledgements-link{
  color: white;
  text-decoration: none;
}

.welcome-page-link {
  color: white;
  text-decoration: none;
}

.disclaimer-link {
  color: white;
  text-decoration: none;
}

.privacy-link {
  color: white;
  text-decoration: none;
}

@media screen and (max-width: 480px) {
  .footer p,
  .footer a.website-link {
    font-size: 13px;
  }
}
