/* HelpPopup.css */

.help-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}

.help-content {
  background: #fff; 
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  max-width: 80%;
  font-size: 16px;
}

.close-button {
  position: absolute;
  top: -1px;
  right: 5px;
  font-size: 24px;
  cursor: pointer;
  color: #555;
}

.scroll-container {
  max-height: 400px;
  overflow-y: auto; 
}


#clo-image, 
#met-image {
  width: 100%; 
  max-height: 250px; 
  display: block; 
  margin: 0 auto; 
}


