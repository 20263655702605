/* Header.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@600&display=swap');

.header {
  background-color: #17699d;
  padding: 5px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1); 
  margin-bottom: 25px;
}

.header-content {
  display: flex;
  align-items: center;
}

.header-title {
  font-size: 22px;
  font-weight: normal;
  margin-left: 15px;
  margin-top: 15px;
  color: rgb(255, 255, 255); 
  height: 4vh; 
  font-family: "Roboto Slab", Arial, sans-serif;
}

.header i {
  margin-left: 15px;
  color: white;
  font-size: 25px;
}

@media screen and (max-width: 600px) {
  .header-title {
    font-size: 15px;
  }
  .header i {
    font-size: 18px;
  }
}