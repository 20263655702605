/* ToggleBar.css */

.toggle-bar {
  max-width: 730px;
  width: 100%;
  margin: 20px auto;
}

.toggle-bar button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border: 2px solid rgb(204, 204, 204);
  padding: 20px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  box-sizing: border-box;
  color: black;
}

.toggle-bar .detailed-descriptions {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 2px solid rgb(204, 204, 204);
  padding: 15px;
  border-radius: 6px;
  width: 100%;
  margin-bottom: 5px;
  /*text-align: left; */
  transition: transform 0.9s ease;
  box-sizing: border-box;
  font-size: 18px;
  color: black;
}



.toggle-bar button:hover {
  background-color: #708be48c;
}

.icon.expanded {
  transform: rotate(180deg);
}

.toggle-bar button.expanded {
  height: auto;
}

.toggle-bar button.expanded .detailed-descriptions {
  visibility: visible;
  transition: all 0.9s ease-in-out;
}

/* Style for the icon container */
.icon-container {
  display: inline-flex;
  align-items: center;
  margin-right: 5px; /* Adjust margin as needed */
}

/* Ensure all icons are the same size */
.icon-container svg {
  width: 30px; /* Adjust the size as needed */
  height: 30px; /* Adjust the size as needed */
}

/* Optional: Add spacing between icon and text */
.recommendation-pair {
  margin-bottom: 15px; /* Adjust spacing as needed */
}

.clothing-disclaimer {
  font-size: 12px;
  color: black; 

}

@media (max-width: 1024px) {
  .toggle-bar{
    width: 75%;
    margin: 20px, 25%;
  }
}

@media (max-width: 530px) {
  .toggle-bar{
    width: 80%;
  }

  .toggle-bar button,
  .toggle-bar .detailed-descriptions {
    font-size: 14px;
    color: black;
    padding: 12px;
  }
}
