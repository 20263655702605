.privacy-policy-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  
  .privacy-policy-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    width: 100%;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .privacy-policy-title {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
  }
  
  .effective-date {
    font-size: 1em;
    color: #666;
    margin-bottom: 20px;
  }
  
  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .section-title {
    font-size: 1.5em;
    color: #444;
    margin-bottom: 10px;
  }
  
  .section-text {
    font-size: 1em;
    color: #666;
    line-height: 1.6;
    text-align: center;
    max-width: 700px; /* Optional: limits text width for readability */
  }
  
  .section-text a {
    color: #007bff;
    text-decoration: none;
  }
  
  .section-text a:hover {
    text-decoration: underline;
  }