
.inputBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
}

.city_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

}

.search-container,
.height_container,
.weight_container,
.met_container,
.clo_container,
.burnsa_container,
.duration_container,
.shade_container{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding: 11px;
}

.city_container .search-container{
  position: relative;
}

.city_container .search-container label,
.height_container label,
.weight_container label,
.met_container label,
.clo_container label,
.burnsa_container label,
.duration_container label,
.shade_container label {
  width: 18%;
  margin-left: 11%;
}


input[type="text"],
  select {
    width: 40%;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 16px;
    background: transparent;
    cursor: pointer;
    margin-left: 10px; 
    color: rgb(104, 99, 99);
}

.city_container i.fa-solid,
.height_container i.fa-solid,
.weight_container i.fa-solid,
.met_container i.fa-solid,
.clo_container i.fa-solid,
.burnsa_container i.fa-solid,
.duration_container i.fa-solid,
.shade_container i.fa-solid {
  right: 40px;
  color: #555; 
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;
}

i.fa-magnifying-glass {
  pointer-events: none;
}

i.fa-ruler {
  pointer-events: none;
}

i.fa-weight-scale {
  pointer-events: none;
}

i.fa-person-running {
  pointer-events: none;
}

i.fa-shirt {
  pointer-events: none;
}

i.fa-person  {
  pointer-events: none;
}

i.fa-cloud-sun {
  pointer-events: none;
}

i.fa-clock {
  pointer-events: none;
}

.height-unit, 
.weight-unit{
  background-color: #17699d;
  color: #f4f4f4;
  font-size: 20px;
  padding: 10px;
  width: 60px;
  border: none;
  border-radius: 10%;
  cursor: pointer;
  margin-right: 20%;
}
.hover-text {
  position: absolute;
  padding: 10px;
  background-color: #333;
  color: #fff;
  font-size: 10px;
  border-radius: 5px;
  pointer-events: none;
  z-index: 1000;
}

.met-help-button,
.clo-help-button,
.burnsurface-help-button {
  background-color: #17699d;
  color: #f4f4f4;
  font-size: 20px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 21.6%;
  padding-block-start: 1px;
  padding-block-end: 1px;
  padding-inline-start: 6px;
  padding-inline-end: 6px;
}

.calculate {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh; 
  margin-bottom: 20px;
  text-align: center;
}

.calculate-button {
  background-color: rgb(25, 95, 152);
  color: white;
  font-size: 18px;
  padding: 15px 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;
}

.calculate-button:hover {
  background-color: rgb(16, 75, 163);
  transform: scale(1.1);
}

.result-box {
  margin-top: 20px;
  text-align: center;
  padding: 15px;
  border-radius: 10px; 
  background-color: #ffffff;
}

.Red{
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 700px;
  margin-top: 20px;
  justify-content: center;
  padding: 15px;
  border-radius: 5px; 
  border: 2px solid rgb(255, 255, 255);
  color: white;
  font-size: 25px;
  height: 15vh;
}

.Orange{
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 700px;
  margin-top: 20px;
  justify-content: center;
  padding: 15px; 
  border-radius: 5px; 
  border: 2px solid rgb(253, 253, 253);
  color: white;
  font-size: 25px;
  height: 15vh;
}

.Yellow{
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 700px;
  margin-top: 20px;
  justify-content: center;
  padding: 15px; 
  border-radius: 5px; 
  border: 2px solid rgb(255, 255, 255);
  font-size: 25px;
  height: 15vh;
}

.Green{
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 700px;
  margin-top: 20px;
  justify-content: center;
  padding: 15px; 
  height: 15vh;
  border-radius: 5px;
  border: 2px solid rgb(255, 255, 255);
  color: white;
  font-size: 25px;
}

.search-results {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 185px; 
  overflow-y: auto; 
  background: #fff;
  border: 1px solid black;
  border-top: none;
  border-radius: 0 0 8px 8px; 
  margin-right: 0.5% ;
  box-sizing: border-box;
}
.search-results ul {
  width: 96%;
  margin-top: -1.5px;
  border-bottom:1px rgb(104, 99, 99);
  list-style: none;
  padding-left: 0.5%;
}
/* Add styles for individual search result items */
.search-results li {
  font-size: 16px;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
}
/* Add hover effect for search result items */
.search-results li:hover {
  background-color: rgba(46, 141, 243, 0.7); 
}

.graph-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 1000px;
}

.graph-container .forecast-chart {
  height: 60vh;
  width: 780px;
  margin-bottom: 3vh;
}

.graph-axis {
  color: #333; 
}

.forecast-title {
  text-align: center; 
  font-size: 20px; 
  font-weight: bold;
}

.legend {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 15px;

}

.legend-color {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%; 
  margin-right: 15px; 
  margin-left: 15px;
}

/* Define the colors for the legend circles */
.green {
  background-color: #7bc774;
}

.yellow {
  background-color: #ddc53a;
}

.orange {
  background-color: #f17d3f;
}

.red {
  background-color: #b83c55;
}

/*///////////////*/
/* media queries */
/*///////////////*/

@media screen and (max-width: 1024px) {
  
  .city_container .search-container label,
  .height_container label,
  .weight_container label,
  .met_container label,
  .clo_container label,
  .burnsa_container label,
  .duration_container label,
  .shade_container label {
    width: 16%;
    margin-left: 8%;
    font-size: 14px;
  }
  input[type="text"],
  select {
    width: 50%;
    font-size: 14px;
  }
  .search-results {
    width: 50%;
    margin-left: 0.5%;
    max-height: 170px; 
  }
  .search-results li {
    font-size: 14px;
  }
  
  .met-help-button,
  .clo-help-button,
  .burnsurface-help-button{
    margin-right: 13.5%;
    font-size: 18px;
  }
  .height-unit,
  .weight-unit{
    margin-right: 12%;
    font-size: 18px;
    padding: 10px;
    width: 48px;
  }
  .result-box,
   .Green,
   .Orange,
   .Yellow,
   .Red{
     width: 70%;
     font-size: 15px;
   }

   .graph-container .forecast-chart{
    width: 82%;
   }
}

@media screen and (max-width: 530px) {
   input[type="text"],
   select {
     width: 60%;
     font-size: 10px;
     padding: 10px;
   }

   .city_container .search-container label,
   .height_container label,
   .weight_container label,
   .met_container label,
   .clo_container label,
   .burnsa_container label,
   .duration_container label,
   .shade_container label{
      font-size: 10px;
      width: 18%;
      margin-left: 4%;
   }

   .search-container i.fa-solid,
   .height_container i.fa-solid,
   .weight_container i.fa-solid,
   .met_container i.fa-solid,
   .clo_container i.fa-solid,
   .burnsa_container i.fa-solid,
   .duration_container i.fa-solid,
   .shade_container i.fa-solid {
     right: 35px;
   }

   .met-help-button,
   .clo-help-button,
   .burnsurface-help-button{
     text-align: center;
     font-size: 12px;
     margin-right: 17px;
     padding-block-start: 2px;
     padding-block-end: 3px;
     padding-inline-start: 6px;
     padding-inline-end: 6px;
   }

   .height-unit,
   .weight-unit{
     text-align: center;
     font-size: 12px;
     padding: 5px;
     width: 25px;
     margin-right: 14px;
   }

   .calculate-button{
      padding: 10px 15px;
      font-size: 14px;
    }

    .search-results {
      width: 60%;
      margin-left: 9.5%;
      max-height: 165px; 
    }
    .search-results li {
      font-size: 10px;
    }
   
   .result-box,
   .Green,
   .Orange,
   .Yellow,
   .Red {
     width: 70%;
     font-size: 14px;
   }

   .calculate-button{
    padding: 10px 20px;
  }

  .graph-container .forecast-chart{
    width: 90%;
    margin-right: 7%;
   }
 }