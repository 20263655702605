.welcome-container {
  text-align: center;
  padding: 50px;
  padding-bottom: 8px;
  }
  
  .welcome-container h1 {
    font-size: 36px;
    margin-bottom: 20px; 
  }

  .welcome-message{
    margin-top: 50px;
  }
  
  .welcome-message,
  .welcome-message2 {
    font-size: 20px;
    margin-bottom: 20px;
    line-height: 1.6;
  }
  
  .welcome-message2{
    font-weight: bold;
  }

  .welcome-button {
    padding: 15px 30px;
    font-size: 16px;
    cursor: pointer;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    margin-top: 7%;
    margin-bottom: 7%;
  }
  
  .welcome-button:hover {
    background-color: #45a049;
  }

  .welcome-message3{
    font-size: 8px;
    font-weight: bold;
    margin-top: 20px;
  }