/* Acknowledgements.css */
.acknowledgement-title {
  width: 100%; /* Use full width of the parent container */
  text-align: center; /* Center the text */
  margin-bottom: 30px; /* Add some margin for separation */
  color: rgb(52, 49, 49);
}

.acknowledgements-container {
  max-width: 45%;
  margin: 0 auto;
  
  .iee,
  .ps,
  .us,
  .utd
  {
    text-align: center;
    background-color: rgba(134, 156, 188, 0.39);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 6px;
    margin-bottom: 10px; /* Add margin between paragraphs */
    border-radius: 3px;
  }

  h5
  {
    font-size: 15px;
    margin-top: 9px;
    margin-bottom: 9px; /* Add margin between paragraphs */
  }

  .iee p,
  .ps p,
  .us p,
  .utd p
  {
    font-size: 15px;
    margin-top: 6px;
    margin-bottom: 6px; /* Add margin between paragraphs */
  }

  .iee:hover,
  .ps:hover,
  .us:hover,
  .utd:hover {
    background-color:  rgba(52, 111, 199, 0.39);
    transform: scale(1.1);
  }

}

.project-funding {
  width: 70%; /* Use full width of the parent container */
  text-align: center; /* Center the text */
  margin: 0 auto;
  color: rgb(52, 49, 49);
  margin-bottom: 20px;
}