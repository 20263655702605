.disclaimer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  
  .disclaimer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    width: 100%;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .disclaimer-title {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .disclaimer-text, .disclaimer-text-bold {
    font-size: 1em;
    color: #666;
    line-height: 1.6;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .disclaimer-text-bold {
    font-size: 1.1em;
    font-weight: bold;
    color: #d9534f; /* Optional: for emphasis */
  }